@import '~@kpn-style/bundle/theme/business-market/business-market';
@import '~@kpn-style/bundle/base/base';
@import '~@kpn-style/bundle/component/component';
@import '~@kpn-style/bundle/layout/layout';

@include font-face('kpn-metric', lighter, 300, '~@kpn-style/bundle/base/typography', 'kpn-metric-light');
@include font-face('kpn-metric', normal, 400, '~@kpn-style/bundle/base/typography', 'kpn-metric-normal');
@include font-face('kpn-metric', semibold, 500, '~@kpn-style/bundle/base/typography', 'kpn-metric-semi-bold');
@include font-face('kpn-metric', bold, 700, '~@kpn-style/bundle/base/typography', 'kpn-metric-bold');
@include font-face('kpn-ui-icons', normal, 400, '~@kpn-style/bundle/base/ui-icon', 'kpn-ui-icons');
@include font-face('kpn-service-icons', normal, 400, '~@kpn-style/bundle/base/service-icon', 'kpn-service-icons');

@import '~@angular/cdk/overlay-prebuilt.css';
@import './angular-material-theme.scss';

html,
body {
  min-width: 320px;
  overflow-x: auto;
}

html,
body,
.app-layout,
.app-layout__main {
  height: 100%;
}

.app-layout__main {
  position: relative;
  height: calc(100% - (#{$top-bar-height} + #{$title-bar-height}));
}

.container {
  box-sizing: border-box;
  height: 100%;
  overflow-y: auto;
}

.content-container {
  padding: 0;
  height: 100%;
  overflow: hidden;
}

.progress-spinner--index:before {
  border-top-color: color(grey, dark);
}

.progress-spinner__container--index,
.progress-spinner--index {
  height: 100%;
  width: 100%;
}

.button:not([disabled]):not(.button--disabled):hover {
  background-color: initial;
}

.app-layout .app-layout__top-bar {
  z-index: 101;
}

@include breakpoint(m) {
  .app-layout .app-layout__side-bar.side-bar + .app-layout__main .title-bar__title,
  .app-layout .app-layout__side-bar .side-bar + .app-layout__main .title-bar__title {
    margin-left: $side-bar-width - space();
  }
}

.button-container.button-container--bottom {
  display: flex;
  justify-content: flex-end;
}

.button-container--bottom {
  margin-top: space();
  padding-top: space(2);
  border-top: 1px solid color(grey, light);
}
